<template>
  <a
    class="position-relative d-flex flex-column flex-lg-row justify-content-between rounded-20 px-20 py-34 mb-10 mb-lg-24 text-decoration-none text-dark"
    :class="(isVacancy ? 'align-items-center ' : '') + (isActive ? 'bg-apply shadow-1' : 'bg-white shadow-2')"
    :href="getAlias(isVacancy ? SearchSource.Vacancies : SearchSource.Summaries, id)"
    @click.prevent
  >
    <div class="d-flex align-items-center gap-lg-15 gap-0 flex-column flex-lg-row w-100 w-lg-auto">
      <div
        class="d-none d-lg-block text-center text-gray"
        style="font-size: 12px"
      >
        <span>Обновлено</span><br>
        <time>
          {{ time.toLocaleDateString(['ru-RU', 'en-US'], {day: '2-digit', month: 'short'}) }}<br>
          {{ time.toLocaleTimeString(['ru-RU', 'en-US'], {hour: '2-digit', minute: '2-digit'}) }}
        </time>
      </div>
      <div
        class="d-lg-block d-flex mb-10 col-lg-auto col-12"
      >
        <div
          class="flex-shrink-0"
          style="height: 80px; width: 80px;"
        >
          <img
            v-if="img !== null"
            class="rounded-20 h-100 w-100"
            :src="img"
            :alt="title"
            style="object-fit: contain;"
          >
        </div>
        <div
          class="d-flex flex-column justify-content-center ps-10"
          style="min-width: 0"
        >
          <span
            class="text-gray mt-5"
            :class="isVacancy ? 'd-lg-none d-block' : 'd-none'"
            style="font-size: 16px"
          >
            {{ subtitle }}
          </span>
          <div
            class="fw-bold d-lg-none d-block text-start overflow-hidden text-nowrap w-100"
            style="font-size: 22px;text-overflow: ellipsis;"
          >
            {{ !isVacancy ? title.split(' ')[0] : title }}
            <br>
            {{ !isVacancy ? title.split(' ')[1] : null }}
          </div>
        </div>
      </div>
      <div class="position-lg-relative w-100 w-lg-auto">
        <span
          v-show="viewed !== undefined"
          class="search-card__visible-status position-absolute top-0 start-lg-0 end-0 mt-5 me-20 text-gray w-lg-100 w-auto"
          style="font-size: 12px"
        >
          Просмотрено {{ getViewedTime }}
        </span>
        <h3
          class="fw-bold mb-0 mt-lg-0 mt-5 d-none d-lg-block"
          :class="isVacancy ? 'text-center text-lg-start' : null"
          style="font-size: 22px"
        >
          {{ title }}
        </h3>
        <div
          :class="isVacancy ? 'd-lg-block d-none' : 'd-block d-lg-none'"
          class="text-gray overflow-hidden text-nowrap"
          style="text-overflow: ellipsis;"
        >
          {{ subtitle }}
        </div>
        <span
          :class="!isVacancy ? 'd-lg-block d-none' : 'd-none'"
          class="text-gray"
        >
          {{ subtitle }}
        </span>
        <div class="text-gray d-none d-lg-block">
          <slot />
        </div>
      </div>
      <div class="text-gray d-lg-none d-block w-100 w-lg-auto">
        <slot />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-20 mt-lg-0 gap-25 w-100 w-lg-auto">
      <div
        v-if="salary.min !== null"
        class="d-none d-lg-inline-block text-primary"
      >
        <span v-if="salary.min !== null">
          от <span class="fw-bold">{{ salary.min.toLocaleString() }}</span>
        </span>
        руб/мес
      </div>
      <time
        class="d-inline-block d-lg-none text-gray"
        style="font-size: 12px"
      >
        {{ time.toLocaleDateString(['ru-RU', 'en-US'], {day: '2-digit', month: 'short'}) }}
        {{ time.toLocaleTimeString(['ru-RU', 'en-US'], {hour: '2-digit', minute: '2-digit'}) }}
      </time>
      <AppButton
        v-if="!hideFavorite"
        class="btn-book flex-shrink-0"
        :class="!isFavorite ? 'border booked' : null"
        :title="isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'"
        variant
        is-icon
        @click.stop.prevent="emits('favorite')"
      >
        <svg class="h-100 w-100">
          <use xlink:href="/public/icons/main.svg#heart-fill" />
        </svg>
      </AppButton>
      <AppButton
        class="d-flex d-lg-none"
        :variant="BtnVariant.Secondary"
        @click.stop.prevent="$emit('openClick')"
      >
        Смотреть
      </AppButton>
    </div>
  </a>
</template>

<script setup lang="ts">
import type { Salary } from "~/entities/salary";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import type { ViewItem } from "~/composables/SearchViewList";
import { useDeclination } from "~/composables/utils/Declination";
import { getAlias } from "~/composables/utils/SearchItemAlias";
import { SearchSource } from "~/composables/enums/SearchSource";
import type { PropType } from "vue";

const emits = defineEmits(['favorite', 'openClick']);
const props = defineProps({
  id: { type: String, required: true },
  title: { type: String, required: true },
  subtitle: { type: String, default: '' },
  img: { type: String, default: null },
  salary: { type: Object as PropType<Salary>, default: () => ({ min: null, max: null }) },
  time: { type: Date, default: new Date() },
  isActive: { type: Boolean, default: false },
  hideFavorite: { type: Boolean, default: false },
  isFavorite: { type: Boolean, default: false },
  isVacancy: { type: Boolean, required: true },
  isViewed: { type: Boolean, default: false },
  viewed: { type: Object as PropType<ViewItem>, default: undefined },
});

//-----COMPUTED-----\\
const getViewedTime = computed<string>(() => {
  if (props.viewed === undefined) {
    return '';
  }

  const diff = (new Date()).getTime() / 1000 - props.viewed.created.getTime() / 1000;
  const secInDay = 86400;
  const secInMonth = secInDay * 30;

  if (diff >= secInMonth * 12) {
    return 'более года назад';
  } else if (diff >= secInMonth * 6) {
    return 'более 6 мес назад';
  } else if (diff >= secInMonth) {
    return Math.ceil(diff / secInMonth) + ' мес. назад';
  } else if (diff >= secInDay) {
    const diffDay = Math.ceil(diff / secInDay);
    return diffDay + ' ' + useDeclination(diffDay, 'день', 'дня', 'дней') + ' назад';
  } else if (diff >= 3600) {
    return 'более часа назад';
  } else if (diff >= 60) {
    return 'менее часа назад';
  }

  return 'только что';
})
</script>

<style scoped lang="scss">
@media (min-width: 992px) {
  .search-card__visible-status {
    top: unset !important;
    bottom: 100% !important;
  }
}
</style>