import {SearchSource} from "~/composables/enums/SearchSource";

export function getAlias(source: SearchSource, id: string): string {
  let alias: string;
  if (source === SearchSource.Vacancies) {
    alias = '/vacancies/';
  } else if (source === SearchSource.Summaries) {
    alias = '/summaries/';
  } else if (source === SearchSource.Companies) {
    alias = '/companies/';
  }

  return alias + id;
}